<template>
  <div class="admin-wrapper">
    <div class="table__container">
      <h2 class="table__container-heading">Projekter</h2>
      <div class="table__filters">
        <div :class="['table__filter', { 'table__filter--active': statusFilter == '' }]" @click="statusFilter = ''">Alle ({{ projects.length }})</div>
        <div :class="['table__filter', { 'table__filter--active': statusFilter == status.id }]" @click="statusFilter = status.id" v-for="status in statusList" :key="status.id">
          {{ status.name }} ({{ filterCount(status.id) }})
        </div>
      </div>
      <div class="table">
        <div class="table__headers">
          <a class="table__header">Firma</a>
          <a class="table__header">Kundenavn</a>
          <a class="table__header">Addresse</a>
          <a class="table__header">Anmodnings dato</a>
          <a class="table__header">Status</a>
        </div>
        <template v-if="!projects.length">
          <div class="table__row">
            <div class="table__cell">No projects found</div>
          </div>
        </template>
        <template v-if="!isLoading">
          <template v-for="project in filteredProjects">
            <router-link
              class="table__row"
              :to="{
                name: 'admin-project',
                params: {
                  id: project.id,
                },
              }"
              :key="project.id"
            >
              <div class="table__cell">{{ project.delivery.company }}</div>
              <div class="table__cell">{{ project.delivery.contactName }}</div>
              <div class="table__cell">{{ project.delivery.address }}</div>
              <div class="table__cell">{{ convertDate(project.requestDate) }}</div>
              <!-- <div class="table__cell">{{ project.requestDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' }) }}</div> -->
              <div :class="['table__cell table__cell--light', { 'table__cell--dark': project.status == 'requested' }, { 'table__cell--active': project.status == 'accepted' }]">
                {{ statusNameById(project.status) }}
              </div>
            </router-link>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DateTime from 'luxon/src/datetime.js'

export default {
  name: 'admin-projects',
  data() {
    return {
      isLoading: false,
      statusFilter: '',
    }
  },
  async created() {
    this.isLoading = true
    if (!this.projects) await this.getProjects()
    this.isLoading = false
  },
  methods: {
    ...mapActions(['getProjects']),
    convertDate(unix) {
      return DateTime.fromMillis(unix).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
    },
    filterCount(status) {
      const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0)
      const statusArray = this.projects.map(project => project.status)
      return countOccurrences(statusArray, status)
    },
    filterProjects(status) {
      this.filteredProjects = this.projects.filter(project => project.status !== status)
    },
  },
  computed: {
    ...mapGetters(['projects', 'statusNameById', 'statusList']),
    filteredProjects() {
      return this.statusFilter == '' ? this.projects : this.projects.filter(project => project.status == this.statusFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.table__container {
  padding: 1.25rem;
  color: #474746;
  background: #faf9f9;
}
.table__container-heading {
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 0.25rem;
}
.table__filters {
  display: flex;
  font-size: 0.935rem;
  padding: 1.25rem 0;
}
.table__filter {
  color: #999999;
  margin-right: 1.5rem;
  cursor: pointer;
}
.table__filter--active {
  color: var(--color-cta);
}
.table {
  display: grid;
  align-content: start;
}
.table__cell,
.table__header {
  font-size: 0.75rem;
  border: none;
  font-family: inherit;
  background-color: transparent;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}
.table__cell {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  color: #4d4f5c;
  outline: none;
  border-radius: 0.5rem;
}
.table__cell--light {
  color: #999999;
}
.table__cell--dark {
  color: #4d4f5c;
}
.table__cell--active {
  color: var(--color-cta);
}
.table__header {
  color: #999999;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
}

.table__headers,
.table__row {
  padding: 0 1.25rem;
  display: grid;
  grid-template-columns: minmax(100px, 160px) minmax(100px, 160px) minmax(100px, 220px) 1fr 90px;
  align-items: center;
  grid-template-rows: 40px;
  gap: 0.5rem;
}

.table__row {
  text-decoration: none;
}

.table__row:nth-child(even) {
  background: #f0eeed;
}
</style>
